import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Pagination from "@/components/Pagination";
import { fetchBalanceRecordList } from "../../../api/finance";
import { fetchMemberList } from "@/api/member";
import "@/utils/global.js";
export default {
  name: "AgentFinanceWithdraw",
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      // 查询字段
      searchForm: {
        page: 1,
        per_page: 20,
        filter: {
          user_id: '',
          biz_type_code: "",
          start_time: "",
          end_time: "",
          keyword: ""
        },
        time: []
      },
      pickerOptions: _global.timeOption,
      bill_date: [],
      // 表格数据
      list: [],
      // 总数
      totalPage: 0,
      tableLoading: false,
      total_num: 0,
      total_deduct_num: 0,
      //搜索用户
      memberLoading: false,
      memberOptions: [],
      bizTypeCodeList: [{
        value: 'grant_time',
        label: "时长发放"
      }, {
        value: 'expired_time',
        label: "时长过期"
      }, {
        value: 'order_deduct_time',
        label: "订单抵扣时长"
      }]
    };
  },
  created: function created() {
    this.fetchList();
  },
  methods: {
    fetchList: function fetchList() {
      var _this = this;

      this.tableLoading = true;
      fetchBalanceRecordList(this.searchForm).then(function (response) {
        _this.list = response.data;
        _this.totalPage = response.meta.total;
        _this.total_num = response.meta.total_num;
        _this.total_deduct_num = response.meta.total_deduct_num;
        _this.tableLoading = false;
      }).catch(function (err) {
        _this.tableLoading = false;

        _this.$message({
          type: "warning",
          message: err.data["message"]
        });
      });
    },
    handleSearch: function handleSearch() {
      console.log("搜索");
      this.searchForm.page = 1;
      this.fetchList();
    },
    handlePageCurrent: function handlePageCurrent(val) {
      this.searchForm.page = val;
      this.fetchList();
    },
    handlePageSize: function handlePageSize(val) {
      this.searchForm.per_page = val;
      this.searchForm.page = 1;
      this.fetchList();
    },
    resetForm: function resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //搜索用户
    remoteMember: function remoteMember(query) {
      var _this2 = this;

      if (query !== "") {
        this.memberLoading = true;
        setTimeout(function () {
          _this2.memberLoading = false;
          var data = {
            keyword: query
          };
          fetchMemberList(data).then(function (res) {
            console.log(res);
            _this2.memberOptions = res.data;
          });
        }, 100);
      } else {
        this.memberOptions = [];
      }
    },
    handleTime: function handleTime(val) {
      if (val) {
        this.searchForm.filter["start_time"] = val[0];
        this.searchForm.filter["end_time"] = val[1];
      } else {
        this.searchForm.filter["start_time"] = "";
        this.searchForm.filter["end_time"] = "";
      }
    },
    getFromValue: function getFromValue(value) {
      var label = '-';
      this.bizTypeCodeList.forEach(function (item) {
        if (item.value == value) {
          label = item.label;
        }
      });
      return label;
    }
  }
};